/* footer */
ul {
    list-style-type: none !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .footer{
    margin-top: 3rem
  }
  .footer-row{
    align-items: baseline;
  }
  .footer-row a{
    display: flex;
    align-items: center;
    gap: 5px !important;
    text-decoration: none;
    color: white;
    border-radius: 32px;
  }
  .footer-row a:hover::after{
    content: "→";
    justify-self: end;
    color: white;
  }
  .footer-row a:hover{
    background: black;
  }
  a > .svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: beige;
    border-radius: 25%;
  }
  svg:not(.loader-container>svg){
    width: 25px;
    height: 25px;
  }
  .footer-row > .col-sm-12{
    gap: 1rem;
  }