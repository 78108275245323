.navibar{
    position: fixed !important;
    font-weight: 500;
    background: transparent;
    z-index: 10 !important;
}
.position-fixed.navbar{
    left: 50%;
    transform: translateX(-50%);
}
.navbar-toggler{
    background: white !important;
}